import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import backgroundImage from './background.jpg'; 
import projectImage from './topcore.png';
import projectImage2 from './ech.png';
import projectImage3 from './snake-game.png';
import projectImage4 from './weather-app.png';
import projectImage5 from './chess-game.png';
import projectImage6 from './the-good-wrench.png';
import serviceImage1 from './service1.jpg';
import serviceImage2 from './service2.jpg';
import serviceImage3 from './service3.jpg';
import serviceImage4 from './service4.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [isScrollUpVisible, setScrollUpVisible] = useState(false);

  useEffect(() => {
    const generateRain = () => {
      const rainContainer = document.querySelector('.rain');
      for (let i = 0; i < 500; i++) {
        const drop = document.createElement('div');
        drop.className = 'drop';
        drop.style.left = `${Math.random() * 100}vw`;
        drop.style.animationDuration = `${Math.random() * 1 + 0.5}s`;
        rainContainer.appendChild(drop);
      }
    };

    generateRain();

    const handleScroll = () => {
      setScrollUpVisible(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    emailjs.init("dM2HU1wklRX9d_rF1"); // Replace with your actual User ID

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    emailjs.sendForm('service_67rqpnh', 'template_62k54ib', form)
      .then((result) => {
        toast.success('Message sent successfully!');
        form.reset();
      }, (error) => {
        console.error('Error:', error);
        toast.error('Error sending message, please try again later.');
      });
  };

  return (
    <Router>
      <div className="app-container">
        <ToastContainer />
        <div className="rain"></div>
        <section className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="hero-content">
            <h1 className="hero-title">Hi, I'm Tumelo Modupi, a passionate Web Developer</h1>
            <div className="hero-buttons">
              <button className="btn-secondary" onClick={() => scrollToSection('about')}>About Me</button>
              <button className="btn-secondary" onClick={() => scrollToSection('services')}>My Services</button>
              <button className="btn-secondary" onClick={() => scrollToSection('portfolio')}>My Work</button>
              <button className="btn-secondary" onClick={() => scrollToSection('contact')}>Contact Me</button>
            </div>
          </div>
        </section>

        <Home handleSubmit={handleSubmit} />

        {isScrollUpVisible && (
          <button className="scroll-up" onClick={scrollToTop}>
            ↑
          </button>
        )}

        <footer className="footer">
          <p>&copy; 2023 Tumelo Modupi. All Rights Reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

const Home = ({ handleSubmit }) => (
  <>
    <section className="about" id="about">
      <div className="about-text">
        <h2 className="section-title">About</h2>
        <p>
          A dedicated developer specializing in web and mobile applications, with expertise in React.js, React Native, HTML, JavaScript, CSS, PHP, and SEO. 
          Focused on delivering seamless, responsive, and user-centric digital solutions that blend aesthetics with functionality. 
          Committed to clean, efficient code and design that prioritizes performance and user experience. With a passion for innovation and problem-solving, every project is approached with a mindset of continuous improvement and delivering meaningful results.
        </p>
      </div>
    </section>

    <section className="services" id="services">
      <h2 className="section-title">Services</h2>
      <div className="service-items">
        <div className="service-item">
          <img src={serviceImage1} alt="Web Development Icon" />
          <p>Web Development</p>
        </div>
        <div className="service-item">
          <img src={serviceImage2} alt="UI/UX Design Icon" />
          <p>UI/UX Design</p>
        </div>
        <div className="service-item">
          <img src={serviceImage4} alt="SEO Icon" />
          <p>SEO</p>
        </div>
        <div className="service-item">
          <img src={serviceImage3} alt="Frontend Icon" />
          <p>Frontend</p>
        </div>
      </div>
    </section>

    <section className="portfolio" id="portfolio">
  <h2 className="section-title">My Work</h2>
  <div className="portfolio-items">
    <div className="portfolio-item">
      <img src={projectImage} alt="Project 1" />
      <h3>Topcore</h3>
      <p>Technologies Used: React.js, CSS</p>
      <a href="https://topcore.co.za" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
    <div className="portfolio-item">
      <img src={projectImage2} alt="Project 2" />
      <h3>Extra Classes Hub</h3>
      <p>Technologies Used: React.js, Node.js, and CSS</p>
      <a href="https://extraclasseshub.co.za" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
    <div className="portfolio-item">
      <img src={projectImage3} alt="Snake Game" />
      <h3>Snake Game</h3>
      <p>Technologies Used: React.js, Node.js, and CSS</p>
      <a href="https://snakesworld.netlify.app" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
    <div className="portfolio-item">
      <img src={projectImage4} alt="Weather App" />
      <h3>Weather App</h3>
      <p>Technologies Used: React.js, CSS, and OpenWeather API</p>
      <a href="https://liveweathers.netlify.app" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
    <div className="portfolio-item">
      <img src={projectImage5} alt="Chess Game" />
      <h3>Chess Game</h3>
      <p>Technologies Used: React.js, Node.js, and CSS</p>
      <a href="https://chessboard-games.netlify.app" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
    <div className="portfolio-item">
      <img src={projectImage6} alt="The Good Wrench" />
      <h3>The Good Wrench</h3>
      <p>Technologies Used: HTML, JavaScript, and CSS</p>
      <a href="https://thegoodwrench.netlify.app" target="_blank" rel="noopener noreferrer" className="btn-primary">View Details</a>
    </div>
  </div>
</section>


    <section className="contact" id="contact">
      <h2 className="section-title">Contact Me</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input type="text" name="user_name" placeholder="Your Name" required />
        <input type="email" name="user_email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your Message" required></textarea>
        <button type="submit" className="btn-submit">Send Message</button>
      </form>
      <div className="social-icons">
        <a href="https://wa.me/+27767362968" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <a href="tel:+27767362968" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faPhoneAlt} />
        </a>
        <a href="https://za.linkedin.com/in/tumelo-modupi-ab5aba311" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </section>
  </>
);

export default App;
